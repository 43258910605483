import React, { useState, useEffect, useContext, useRef } from 'react'
import { StyleSheet, Text, View, Dimensions, Animated } from 'react-native'
import CustomText from '../customFutureBrowserText/index'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../Utils/responsive'
import { withRouter } from 'react-router-dom';
import { ActivityContext } from '../../Containers/Activities/index'
import { chunkify } from '../../Utils/chunkify'
import './index.css'
import logoBeIQ from '../../assets/images/FutureModule/logoBeIQ.png'
import leftHandle from '../../assets/images/FutureModule/leftHandleDark.png'
import rightHandle from '../../assets/images/FutureModule/rightHandle.png'

export const WidthDimension = Dimensions.get("screen").width;
export const heightLeftHandle = hp("68%");
export const heightRightHandle = hp("46%");
export const widthLeftHandle = wp("20%");
export const widthRightHandle = wp("20%");

let isSafari = false;
var ua = navigator.userAgent.toLowerCase();
if (ua.indexOf('safari') != -1) {
  if (ua.indexOf('chrome') > -1) {
    isSafari = false;
  } else {
    isSafari = true
  }
}


const Index = ({ moduleObject, moduleIndex, setModuleIndex, selectAnswerHandler, question, history }) => {
    const [allValue, setAllValue] = useState(null);
    const [indexing, setIndexing] = useState(null);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [currentSet, setCurrentSet] = useState(0);
    const [hide, setHide] = useState(false);
    const leftHandleAnimation = useState(new Animated.Value(-300))[0];
    const rightHandleAnimation = useState(new Animated.Value(300))[0];
    const resultContext = useContext(ActivityContext);
    const firstHandle = useRef(null);
    const secondHandle = useRef(null);

    useEffect(() => {
        if (moduleObject.questions) {
            const chunkiElement = chunkify(moduleObject.questions, 2, true);

            setAllValue(chunkiElement);

            const totalLength = chunkiElement.map(elem => (
                { length: elem.length }
            ))
            setIndexing(totalLength)
        }

        Animated.parallel([
            Animated.timing(leftHandleAnimation, {
                toValue: 0,
                duration: 1000,
                useNativeDriver: false,
            }),
            Animated.timing(rightHandleAnimation, {
                toValue: 0,
                duration: 1000,
                useNativeDriver: false
            })
        ]).start()
    }, [])

    const saveAnswer = (index) => {
        selectAnswerHandler(moduleObject.moduleid, allValue[currentSet][questionIndex].qid, allValue[currentSet][questionIndex].answers[index].respid)
    }

    const handleButtonClick = (val) => {
        if (val === "Left") {
            if (questionIndex < indexing[currentSet].length - 1) {
                saveAnswer(0)
                setQuestionIndex(prev => prev + 1)
            }
            else {
                saveAnswer(0)
                setQuestionIndex(0)
                setCurrentSet(prev => prev + 1)
            }
        }
        else {
            if (questionIndex < indexing[currentSet].length - 1) {
                for (let i = questionIndex; i < indexing[currentSet].length - 1; i++) {
                    selectAnswerHandler(moduleObject.moduleid, allValue[currentSet][i].qid, allValue[currentSet][i].answers[1].respid)
                }
            }
            selectAnswerHandler(moduleObject.moduleid, allValue[currentSet][indexing[currentSet].length - 1].qid, allValue[currentSet][indexing[currentSet].length - 1].answers[1].respid)
            setCurrentSet(prev => prev + 1)
            setQuestionIndex(0)
        }
    }

    const rotateLeftAnimation = leftHandleAnimation.interpolate({
        inputRange: [-300, 0],
        outputRange: ["-90deg", "0deg"]
    })

    const rotateRightAnimation = rightHandleAnimation.interpolate({
        inputRange: [0, 300],
        outputRange: ["0deg", "90deg"]
    })

    const LeftRotateAnimation = [{ translateY: heightLeftHandle / 2 }, { translateX: widthLeftHandle / 2 }, { rotate: rotateLeftAnimation }, { translateY: -heightLeftHandle / 2 }, { translateX: -widthLeftHandle / 2 }]

    const rightRotateAnimation = [{ translateY: heightLeftHandle / 2 }, { translateX: -widthLeftHandle / 2 }, { rotate: rotateRightAnimation }, { translateY: -heightLeftHandle / 2 }, { translateX: widthLeftHandle / 2 }]

    const HandleLeftClick = () => {
        setHide(true)
        if (currentSet !== 2 && moduleIndex < indexing[currentSet].length - 1) {
            firstHandle.current = setTimeout(() => {
                handleButtonClick("Right");
            }, 600)
        }
        else {
            handleButtonClick("Right");
        }
        Animated.sequence([
            Animated.timing(leftHandleAnimation, {
                toValue: -300,
                duration: 800,
                useNativeDriver: false,
            }),
            Animated.timing(leftHandleAnimation, {
                toValue: 0,
                duration: 800,
                useNativeDriver: false,
            })
        ]).start(() => setHide(false))
        if(currentSet === 0){
            Animated.sequence([
                Animated.timing(rightHandleAnimation, {
                    toValue: 300,
                    duration: 800,
                    useNativeDriver: false,
                }),
                Animated.timing(rightHandleAnimation, {
                    toValue: 0,
                    duration: 800,
                    useNativeDriver: false,
                })
            ]).start(() => setHide(false))
        }
    }


    const handleRightClick = () => {
        setHide(true)
        if (currentSet !== 2 && moduleIndex < indexing[currentSet].length - 1) {
            secondHandle.current = setTimeout(() => {
                handleButtonClick("Left");
            }, 600)
        }
        else {
            handleButtonClick("Left");
        }
        Animated.sequence([
            Animated.timing(leftHandleAnimation, {
                toValue: -300,
                duration: 800,
                useNativeDriver: false,
            }),
            Animated.timing(leftHandleAnimation, {
                toValue: 0,
                duration: 800,
                useNativeDriver: false,
            })
        ]).start(() => setHide(false))
    }

    useEffect(() => {
        if (currentSet === 2) {
            resultContext.setModuleCompletedAtTime(moduleObject.moduleid);
            history.push('/')
        }
    }, [currentSet])

    return (
        <div className="container">
            <div className="topSpace">
                <div className="Top-Border" >
                </div>
                {/* <img src={logoBeIQ} alt="logo" className="Logo" /> */}
                <img src={require("../../assets/svg/futurelogo.svg")} style={{
                    position: "relative",
                    alignSelf: "flex-end",
                    width: WidthDimension > 400 ? 54 : 40,
                    objectFit: 'contain',
                    height: WidthDimension > 400 ? 16 : 12,
                    marginRight: 7,
                }}
                    className="Logo" />
            </div>
            <div className="mainContainer">
                <div className="topContainer">
                    <View style={styles.headingContainer}>
                        <View style={styles.Line} />
                        <Text style={styles.header}>I'd rather have...</Text>
                        <View style={styles.Line2} />
                    </View>
                </div>
                <div className={`handleContainer ${isSafari ? 'handleContainerSafari': ''}`} >
                    <Animated.View style={[{ transform: LeftRotateAnimation }, styles.custom]}>

                        <div className="leftText" onClick={() => {
                            !hide &&
                                HandleLeftClick();
                        }}
                        >
                            {(allValue !== null && currentSet !== 2) && <CustomText data={moduleObject.questions[moduleIndex].answers[1].text} text="First" />}
                        </div>

                        <img src={leftHandle} alt="leftHandle" className={`leftHandle ${isSafari ? 'leftHandleSafari' : ""}`} />
                    </Animated.View>
                    <Animated.View style={[{ transform: rightRotateAnimation }, styles.custom2]}>
                        <div className="rightText" onClick={() => {
                            !hide && handleRightClick();
                        }}  >
                            {(allValue !== null && currentSet !== 2) && <CustomText data={moduleObject.questions[moduleIndex].answers[0].text} text="second" />}

                        </div>
                        <img src={rightHandle} alt="rightHandle" className={`rightHandle  ${isSafari ? 'rightHandleSafari' : ""}`} />
                    </Animated.View>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Index)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#eeeeee",
        height: "100vh",
        width: "100vw"
    },
    headingContainer: {
        width: WidthDimension < 600 ? "321px" : "534px",
        // height: 76,
        height: "auto",
        justifyContent: "space-between",
        alignItems: "center",
        // alignSelf:"center",
        marginBottom: hp("4%")
    },
    borderTop: {
        position: "relative",
        borderTopColor: "#fda435",
        borderTopWidth: 3,
        marginBottom: 10
    },
    logo: {
        position: "relative",
        alignSelf: "flex-end",
        width: wp("9.7%"),
        resizeMode: "contain",
        // marginRight: 100,
        height: hp('1.4%')
    },

    Line: {
        position: "relative",
        width: WidthDimension < 600 ? 250 : 400,
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',
    },

    Line2: {
        position: "relative",
        width: WidthDimension < 600 ? 250 : 400,
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',
    },
    header: {
        position: "relative",
        width: "100%",
        height: "auto",
        alignSelf: 'center',
        fontFamily: 'JosefinSans-Light',
        fontSize: 40,
        letterSpacing: 4.8,
        marginTop: 7,
        // fontWeight: '300',
        textAlignVertical: "center",
        textAlign: 'center',
        color: '#575f69',
        textTransform: "uppercase"
    },
    heading: {
        marginVertical: hp("9.2%"),
        alignSelf: "center",
        width: 280,
        height: 76,
        resizeMode: "contain"
    },
    headingTab: {
        marginVertical: hp("9.2%"),
        alignSelf: "center",
        width: 534,
        height: 76,
        resizeMode: "contain"
    },
    custom: {
        overflow: 'hidden',
        height: "80%",
        width: '50%'
        // backgroundColor:"red"
    },
    custom2: {
        overflow: 'hidden',
        height: "58%",
        width: '50%'
        // backgroundColor:'red'
    },
    handleBox: {
        // flex:1,
        marginHorizontal: 100,
        width: wp("80%"),
        alignItems: "flex-end",
        position: "relative",
        justifyContent: "center",
        flexDirection: "row",
        // backgroundColor:"blue"
    },
    handleLeft: {
        width: WidthDimension < 5000 ? widthLeftHandle : 218,
        height: WidthDimension < 5000 ? heightLeftHandle : 580,
        resizeMode: 'contain',
        // backgroundColor:"green",
        // alignSelf:"flex-end"

    },
    customBorder: {
        width: WidthDimension < 5000 ? wp("46%") : 188,
        height: WidthDimension < 5000 ? hp("20%") : 188,
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        zIndex: 2,
        alignSelf: "center",
        // backgroundColor:"red",
        // borderRadius:15.2
    },
    handleRight: {
        width: WidthDimension < 5000 ? widthRightHandle : 282,
        height: WidthDimension < 5000 ? heightRightHandle : 440,
        resizeMode: 'contain',
        // backgroundColor:"black",
        // alignSelf:"flex-end"
    },
    customBorder2: {
        width: WidthDimension < 5000 ? wp("46%") : 242,
        height: WidthDimension < 5000 ? hp("27%") : 242,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        // backgroundColor:"red",
        position: "absolute",
    },
    amount: {
        color: "#ffffff",
        width: WidthDimension < 5000 ? wp('44%') : 130,
        height: WidthDimension < 5000 ? hp('5%') : 38,
        fontFamily: "JosefinSans-Regular",
        fontSize: WidthDimension < 5000 ? hp('4.5%') : 32,
        fontWeight: "bold",
        // alignSelf:"center",
        fontStyle: "normal",
        textAlign: "center",
    },
    amount2: {
        color: "#ffffff",
        width: WidthDimension < 5000 ? wp('58%') : 158,
        height: WidthDimension < 5000 ? hp('5%') : 49,
        fontFamily: "JosefinSans-Regular",
        // backgroundColor:"blue",
        fontSize: WidthDimension < 5000 ? hp('4.5%') : 41.4,
        fontWeight: "bold",
        alignSelf: "center",
        fontStyle: "normal",
        textAlign: "center",
    },
    label: {
        minWidth: WidthDimension < 5000 ? wp('38%') : 122,
        height: WidthDimension < 5000 ? hp('5%') : 29,
        fontFamily: "JosefinSans-Regular",
        fontSize: WidthDimension < 5000 ? hp('2.7%') : 24,
        fontWeight: "normal",
        fontStyle: "normal",
        textAlign: "center",
        color: "#ffffff",

    },
    label2: {
        minWidth: WidthDimension < 5000 ? wp('42%') : 158,
        height: WidthDimension < 5000 ? hp('5%') : 37,
        fontFamily: "JosefinSans-Regular",
        fontSize: WidthDimension < 5000 ? hp('2.8%') : 31,
        fontWeight: "normal",
        fontStyle: "normal",
        textAlign: "center",
        color: "#ffffff",

    }


})
